import './HeaderImage.scss';

import PropTypes from 'prop-types';
import React from 'react';

import {withRouter} from 'react-router-dom';

class ContentLayout extends React.Component {
  render() {
    const {routes} = this.props;
    const currentPath = this.props.location.pathname;
    const routeObject = routes.find((obj) => obj.url === currentPath);
    const CurrentPageComponent = routeObject.Component;

    let headerUrl = 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184255/IMG_7984_scaled_sqtzog.jpg';
    if (typeof CurrentPageComponent.getHeaderUrl === 'function') {
      headerUrl = CurrentPageComponent.getHeaderUrl(this.props.authenticated);
    }

    return (
      <div
        className="header-image"
        style={{backgroundImage: `url("${headerUrl}")`}}
      >
      </div>
    );
  }
}

ContentLayout.propTypes = {
  authenticated: PropTypes.bool,
  location: PropTypes.object,
  routes: PropTypes.array,
};

export default withRouter(ContentLayout);
