import {
  LOAD_CONTACTPERSONS_BEGIN,
  LOAD_CONTACTPERSONS_FAIL,
  LOAD_CONTACTPERSONS_SUCCESS,
} from '@Actions/contactPersons.js';

const initialcontactPersonsStates = {
  contactPersons: {
    contactPersons: {},
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
  },
};

// eslint-disable-next-line
  export default function contactPersonsReducer(state = {...initialcontactPersonsStates}, action) {
  switch (action.type) {
    case LOAD_CONTACTPERSONS_BEGIN:
      return {
        ...state,
        contactPersons: {
          ...state.contactPersons,
          fetched: false,
          fetching: true,
        },
      };
    case LOAD_CONTACTPERSONS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {contactPersonsObj} = action;
      return {
        ...state,
        contactPersons: {
          ...state.contactPersons,
          contactPersons: contactPersonsObj.contactPersons,
          error: false,
          fetched: true,
          fetching: false,
        },
      };
    case LOAD_CONTACTPERSONS_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {contactPersonsError} = action;
      return {
        ...state,
        contactPersons: {
          ...state.contactPersons,
          error: true,
          errorMsg: contactPersonsError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
