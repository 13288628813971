import './index.css';

import {applyMiddleware, createStore} from 'redux';

import App from './App/App';
import {Provider} from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';

import logger from 'redux-logger';
import reducer from '@Reducers/index';
import thunkMiddleware from 'redux-thunk';

const middleware = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const store = createStore(reducer,
    applyMiddleware(...middleware));

const app =
  <Provider store={store}>
    <App />
  </Provider>;

ReactDOM.render(app, document.getElementById('root'));
