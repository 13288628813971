import {
  LOAD_DRINKS_BEGIN,
  LOAD_DRINK_FAIL,
  LOAD_DRINK_SUCCESS,
} from '@Actions/drinks';

const initialMenuState = {
  draftMenuUrl: null,
  drinks:
  {
    dark: [
      {
        abv: '13.37%',
        brewery: 'Bingo brewery',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Beer_mug.svg/2048px-Beer_mug.svg.png',
        name: 'chingo beer',
        price: '1337',
      },
      {
        abv: '4.2%',
        brewery: 'Bingo brewery',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAfdDLNtfk32CJ4w7_zVtwKxNB5HLDqSDWKA&usqp=CAU',
        name: 'chongo beer',
        price: '42',
      },
    ],
    light: [
      {
        abv: '4.20%',
        brewery: 'Bingo brewery',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Beer_mug.svg/2048px-Beer_mug.svg.png',
        name: 'bingo beer',
        price: '420',
      },
      {
        abv: '6.9%',
        brewery: 'Bingo brewery',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAfdDLNtfk32CJ4w7_zVtwKxNB5HLDqSDWKA&usqp=CAU',
        name: 'bango beer',
        price: '69',
      },
    ],
  },
  error: false,
  errorMsg: null,
  fetched: false,
  fetching: false,
};

// eslint-disable-next-line default-param-last
export default function drinksReducer(state = {...initialMenuState}, action) {
  switch (action.type) {
    case LOAD_DRINKS_BEGIN:
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    case LOAD_DRINK_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {drinks} = action;
      return {
        ...state,
        draftMenuUrl: drinks.image_url,
        error: false,
        fetched: true,
        fetching: false,
      };
    case LOAD_DRINK_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {error} = action;
      return {
        ...state,
        error: true,
        errorMsg: error,
        fetched: true,
        fetching: false,
      };
    default:
      return state;
  }
}
