import CircularLoader from '@Components/CircularLoader';
import Grid from '@mui/material/Grid';
import MapComponent from './MapComponent';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';
import {Typography} from '@mui/material';

import {connect} from 'react-redux';
import {fetchContactPersons} from '@Actions/contactPersons';

const ContactPersons = (props) => {
  const {contactPersons} = props;
  return (
    <Grid
      spacing={1}
      container
    >
      {/* eslint-disable-next-line max-len */}
      {contactPersons.map((info) => <Grid item xs={12} sm={12} md={12} key={info.position}>
        <Typography variant="h6" gutterBottom>
          {info.position}
        </Typography>
        <Typography variant="body1" gutterBottom >
          {info.name}<br />
          {info.email}<br />
          {info.phone}<br />
        </Typography>
      </Grid>)}
    </Grid>
  );
};

ContactPersons.propTypes = {
  contactPersons: PropTypes.array.isRequired,
};

const contactCardsInfo = (contactPersons) => [
  {
    message:
      <Typography variant="body1" gutterBottom>
        För mer information om hur ni gör en bokning
        se <a href="/bokning">bokningssidan</a>.
      </Typography>,
    size: {
      md: 6,
      sm: 6,
      xs: 12,
    },
    title: 'Är du intresserad av en bokning?',
  },
  {
    message:
      <Typography variant="body1" gutterBottom>
        VilleValla Pub ligger i stadsdelen Valla i Linköping, ungefär mitt
        emellan innerstaden och universitetet. <br/>
        <br/> <b>VilleValla Pub</b>
        <br/>Vallavägen 4C
        <br/>582 15 Linköping
      </Typography>,
    size: {
      md: 6,
      sm: 6,
      xs: 12,
    },
    title: 'Hitta hit',
  },
  {
    message: <MapComponent />,
    size: {
      md: 6,
      sm: 6,
      xs: 12,
    },
    title: '',
  },
  {
    message: <ContactPersons contactPersons={contactPersons} />,
    size: {
      md: 6,
      sm: 6,
      xs: 12,
    },
    title: 'Kontakta oss',
  },
];

const ContactCard = (props) => {
  const {person} = props;
  return (
    <Grid item xs={person.size.xs} sm={person.size.sm} md={person.size.md}>
      <PageTextCard header={person.title} elevation={3}>
        {person.message}
      </PageTextCard>
    </Grid>
  );
};

ContactCard.propTypes = {
  person: PropTypes.object.isRequired,
};

const ContactCards = (props) => {
  const {contactPersonsObj} = props;
  const persons = contactCardsInfo(contactPersonsObj.contactPersons);

  return (
    <>
      {persons.map((person) => <ContactCard person={person}
        key={person.title}/>)}
    </>
  );
};

ContactCards.propTypes = {
  contactPersonsObj: PropTypes.object.isRequired,
};

class Kontakt extends React.Component {
  static getHeaderUrl(isAuthenticated) {
    if (isAuthenticated) {
      return 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708187168/kitchenHazmat-scaled_qlnciy.jpg';
    }
    return 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1640304500/sofforna_z0ojcn.jpg';
  }

  render() {
    const {contactPersons} = this.props;

    const loaded = !contactPersons.fetching && contactPersons.fetched;
    if (!loaded && !contactPersons.fetching) {
      this.props.fetchContactPersons();
    }
    return (
      <React.Fragment>
        <CircularLoader reducer={contactPersons} action={fetchContactPersons}>
          <ContactCards contactPersonsObj={contactPersons} />
        </CircularLoader>
      </React.Fragment>
    );
  }
}

Kontakt.propTypes = {
  contactPersons: PropTypes.object.isRequired,
  fetchContactPersons: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  contactPersons: state.contactPersonsReducer.contactPersons,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContactPersons: () => dispatch(fetchContactPersons()),
});

export default connect(mapStateToProps,
    mapDispatchToProps)(Kontakt);
