import {
  SET_LANG,
} from '@Actions/lang.js';

const initialLangState = {
  lang: 'sv',
};

// eslint-disable-next-line default-param-last
export default function menuReducer(state = {...initialLangState}, action) {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    default:
      return state;
  }
}
