import api from 'App/api';

export const LOAD_DRINKS_BEGIN = 'LOAD_DRINKS_BEGIN';
export const LOAD_DRINK_SUCCESS = 'LOAD_DRINK_SUCCESS';
export const LOAD_DRINK_FAIL = 'LOAD_DRINK_FAIL';

export const loadDrinksBegin = () => ({
  type: LOAD_DRINKS_BEGIN,
});

export const loadDrinksSuccess = (drinks) => ({
  drinks,
  type: LOAD_DRINK_SUCCESS,
});

export const loadDrinksFail = (error) => ({
  error,
  type: LOAD_DRINK_FAIL,
});

export const fetchDrinkMenuData = () => (dispatch) => {
  dispatch(loadDrinksBegin());
  api.get('draft_menu').
      then((res) => res.data).
      then((json) => {
        dispatch(loadDrinksSuccess(json));
      }).
      catch((err) => {
        dispatch(loadDrinksFail(err));
      });
};
