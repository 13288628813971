import {loginUser, logoutUser} from '@Actions/auth';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react';

export const useAuth = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line max-len
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
  const currentUser = useSelector((state) => state.authReducer.currentUser);
  const error = useSelector((state) => state.authReducer.error);

  const login = useCallback((username, password) => {
    dispatch(loginUser(username, password));
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  return {
    currentUser,
    error,
    isAuthenticated,
    login,
    logout,
  };
};
