/* eslint-disable max-classes-per-file */
import './NavBar.scss';

import 'App/Views/Login/Login';

import AccountMenu from './AccountMenu';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@mui/material/Slide';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {connect} from 'react-redux';
import {setLanguage} from '@Actions/lang';
import {setScroll} from '@Actions/scroll';
import {useAuth} from '@Hooks/AuthContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const NavBar = function NavBar(props) {
  const {isAuthenticated} = useAuth();
  const desktop = useMediaQuery('(min-width:700px)');
  const scrolled = useScrollTrigger();
  if (desktop) {
    return (
      <DesktopNavBar scrolled={scrolled} internal={isAuthenticated}{...props}/>
    );
  }
  return (
    <MobileNavBar scrolled={scrolled} internal={isAuthenticated}{...props}/>
  );
};

class DesktopNavBar extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.scrolled !== prevProps.scrolled) {
      this.props.setScroll({
        height: 64,
        scrolled: this.props.scrolled,
      });
    }
  }

  render() {
    const navbarLinks = this.props.routes.map((page) => <NavLink
      className="navbar-link--desktop"
      to={page.url}
      key={page.url}
      exact
      activeClassName="navbar-link-current--desktop"
    >
      <Typography noWrap sx={{typography: {md: 'h6',
        sm: 'body1'}}}>
        {page.name}
      </Typography>
    </NavLink>);

    return (
      <Slide appear={false} direction="down" in={!this.props.scrolled}>
        <AppBar>
          <Toolbar className="navbar--desktop">
            <div className="navbar-link-container--desktop" >
              <NavLink
                to="/"
              >
                {this.props.internal ?
<img className="navbar-logo--desktop" alt=""
  src="/images/VVLogo-construction.png" /> :
<img className="navbar-logo--desktop" alt=""
  src="/images/RedLogo_shadow.png" />}
              </NavLink>
              {navbarLinks}
            </div>
            <div className="navbar-button-container--desktop">
              <Button
                onClick={() => this.props.setLanguage(this.props.lang === 'sv' ?
'en' :
'sv')}
                color="secondary"
              >
                {this.props.lang}
              </Button>
              <AccountMenu />
            </div>
          </Toolbar>
        </AppBar>
      </Slide>
    );
  }
}

DesktopNavBar.propTypes = {
  internal: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  scrolled: PropTypes.bool.isRequired,
  setLanguage: PropTypes.func.isRequired,
  setScroll: PropTypes.func.isRequired,
};

class MobileNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {drawerOpen: false};
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrolled !== prevProps.scrolled) {
      this.props.setScroll({
        height: 48,
        scrolled: this.props.scrolled,
      });
    }
  }

  render() {
    const navbarLinks = this.props.routes.map((page) => <NavLink
      className="navbar-link--mobile"
      to={page.url}
      key={page.url}
      exact
      activeClassName="navbar-link-current--mobile"
      onClick={() => this.setState({drawerOpen: false})}
    >
      <ListItem button>
        <ListItemText primary={page.name}/>
      </ListItem>
    </NavLink>);


    return (
      <React.Fragment>
        <SwipeableDrawer anchor={'right'} open={this.state.drawerOpen}
          onClose={() => this.setState({drawerOpen: false})}
          onOpen={() => this.setState({drawerOpen: true})}
        >
          <List className="navbar-menu--mobile">
            {navbarLinks}
          </List>
        </SwipeableDrawer>
        <Slide appear={false} direction="down" in={!this.props.scrolled}>
          <AppBar>
            <Toolbar className="navbar--mobile">
              <NavLink to="/">
                {this.props.internal ?
<img className="navbar-logo--mobile" alt=""
  src="/images/VVLogo-construction.png" /> :
<img className="navbar-logo--mobile" alt=""
  src="/images/RedLogo_shadow.png" />}
              </NavLink>
              <div className="navbar-button-container--mobile">
                <IconButton size="large" edge="end" color="inherit"
                  onClick={() => this.setState({drawerOpen: true})}>
                  <MenuIcon />
                </IconButton>
                <AccountMenu/>
              </div>
            </Toolbar>
          </AppBar>
        </Slide>
      </React.Fragment>
    );
  }
}

MobileNavBar.propTypes = {
  internal: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  scrolled: PropTypes.bool.isRequired,
  setLanguage: PropTypes.func.isRequired,
  setScroll: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (lang) => dispatch(setLanguage(lang)),
  setScroll: (event) => dispatch(setScroll(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
