import {
  AUTH_ERROR,
  LOGIN_BEGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_BEGIN,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
} from '@Actions/auth.js';

const initialLoginState = {
  currentUser: localStorage.getItem('currentUser') || null,
  error: null,
  isAuthenticated: localStorage.getItem('isAuthenticated') || false,
  loading: false,
};

// eslint-disable-next-line default-param-last, max-lines-per-function
export default function authReducer(state = {...initialLoginState}, action) {
  switch (action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOGIN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const loginSuccessObj = action.payload;
      return {
        ...state,
        ...loginSuccessObj,
        currentUser: loginSuccessObj.user,
        error: null,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      // eslint-disable-next-line no-case-declarations
      const loginFailObj = action.payload.error;
      return {
        ...state,
        currentUser: null,
        error: loginFailObj,
        isAuthenticated: false,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null,
        isAuthenticated: false,
        loading: false,
      };
    case LOGOUT_FAIL:
      // eslint-disable-next-line no-case-declarations
      const logoutFailObj = action.payload.response.data.error;
      return {
        ...state,
        error: logoutFailObj,
        loading: false,
      };
    default:
      return state;
  }
}
