import './PageTextCard.scss';

import {Grid, Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';


export default class PageTextCard extends React.Component {
  render() {
    const {header, children, className, ...props} = this.props;
    const newClassName = `paper-container ${className}`;
    return (
      <Paper className={newClassName} {...props}>
        <Grid
          container
        >
          {header &&
            <Grid
              item
              xs={12}
            >
              <Typography variant="h4" gutterBottom color="primary">
                {header}
              </Typography>
            </Grid>
          }
          {children}
        </Grid>
      </Paper>
    );
  }
}

PageTextCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.string,
};
