import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from '@Actions/auth';

import authReducer from './auth';
import bookingReducer from './booking';
import calendarEventsReducer from './calendarEvents';
import {combineReducers} from 'redux';
import contactPersonsReducer from './contactPersons';
import drinksReducer from './drinks';
import langReducer from './lang';
import menuReducer from './menu';
import messagesReducer from './messages';
import openHoursReducer from './openHours';
import scrollReducer from './scroll';


const appReducer = combineReducers({
  authReducer,
  bookingReducer,
  calendarEventsReducer,
  contactPersonsReducer,
  drinksReducer,
  langReducer,
  menuReducer,
  messagesReducer,
  openHoursReducer,
  scrollReducer,
});

const rootReducer = (state, action) => {
  // Reset state to initial state when user logs in or out
  if (action.type === LOGOUT_SUCCESS || action.type === LOGIN_SUCCESS) {
    // eslint-disable-next-line no-undefined, no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
