import api from 'App/api';

export const LOAD_PUBLICMESSAGES_BEGIN = 'LOAD_PUBLICMESSAGES_BEGIN';
export const LOAD_PUBLICMESSAGES_SUCCESS = 'LOAD_PUBLICMESSAGES_SUCCESS';
export const LOAD_PUBLICMESSAGES_FAIL = 'LOAD_PUBLICMESSAGES_FAIL';

export const loadPublicMessagesBegin = () => ({
  type: LOAD_PUBLICMESSAGES_BEGIN,
});

export const loadPublicMessagesSuccess = (publicMessagesObj) => ({
  publicMessagesObj,
  type: LOAD_PUBLICMESSAGES_SUCCESS,
});

export const loadPublicMessagesFail = (error) => ({
  error,
  type: LOAD_PUBLICMESSAGES_FAIL,
});

export const fetchPublicMessages = () => (dispatch) => {
  dispatch(loadPublicMessagesBegin());
  api.get('public_messages').
      then((res) => res.data).
      then((json) => {
        const publicMessagesObj = {publicMessages: json};
        dispatch(loadPublicMessagesSuccess(publicMessagesObj));
      }).
      catch((err) => {
        dispatch(loadPublicMessagesFail(err));
      });
};
