
import {
  LOAD_BOOKING_MENU_BEGIN,
  LOAD_BOOKING_MENU_FAIL,
  LOAD_BOOKING_MENU_SUCCESS,
} from '@Actions/booking.js';

const initialMenuState = {
  bookingMenu: {
    bookingMenu: {},
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
  },
};

// eslint-disable-next-line default-param-last
export default function bookingReducer(state = {...initialMenuState}, action) {
  switch (action.type) {
    case LOAD_BOOKING_MENU_BEGIN:
      return {
        ...state,
        bookingMenu: {
          ...state.bookingMenu,
          fetched: false,
          fetching: true,
        },
      };
    case LOAD_BOOKING_MENU_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {bookingMenuObj} = action;

      return {
        ...state,
        bookingMenu: {
          ...state.bookingMenu,
          bookingMenu: bookingMenuObj.bookingMenu,
          error: false,
          fetched: true,
          fetching: false,
        },
      };
    case LOAD_BOOKING_MENU_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {error} = action;

      return {
        ...state,
        bookingMenu: {
          ...state.bookingMenu,
          error: true,
          errorMsg: error,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
