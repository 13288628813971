import './MapComponent.scss';

import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.js';
import React from 'react';

export default class MapComponent extends React.Component {
  render() {
    this.url = 'https://maps.google.com/maps?q=villevalla&t=&z=17&ie=UTF8&iwloc=&output=embed';
    return (
      <React.Fragment>
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe width="100%" height="100%" id="gmap_canvas" src={this.url}
              frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
