export const historien = `
<p>Tanken på att starta en studentpub i Valla/T1 har funnits i huvudet
på många studenter och försök har gjorts förut, men av olika
anledningar har de gått i graven. När tanken på VilleValla föddes var
det alltså knappast något nytt eller banbrytande. Däremot hade
VilleValla, till skillnad från tidigare projekt, tur med
tajmingen. Det är många bitar som måste falla på plats i skapandet av
en ny studentpub. Men för att inte gå denna historia i förväg börjar
vi från början.</p> <p>Hösten 2002, gjorde undertecknad slag i saken
och ringde till Stångis och undrade om de inte hade en lokal för en ny
studentpub. De lovade att undersöka saken så var arbetet igång. Tyvärr
visade det sig ganska snabbt att Stångis inte hade några lämpliga
lokaler, men de uppmuntrade mig att fortsätta jakten. Till att börja
med var arbetet relativt sporadiskt och framförallt inriktat på att
hitta potentiella lokaler. Många företag och personer var väldigt
hjälpsamma vilket gjorde det hela väldigt kul och vid årsskiftet var
lokalen funnen.</p>

<p>Våren och sommaren 2003 gick åt till att övertyga Sankt Kors,
fastighetsägaren, om förträffligheten med att öppna en studentpub i
villan på Westmansgatan 41. Detta visade sig inte helt självklart, men
till sist var de med på noterna.</p> <p>Fram till denna tidpunkt hade
jag mest jobbat själv med projektet, dels därför att mycket
fortfarande var väldigt osäkert och dels för att jag inte ville
engagera folk i något som skulle visa sig helt ogenomförbart. Men nu
fanns grundförutsättningarna för att ta projektet till en ny fas och
även tillräckliga skäl att arbeta mer formellt och kontinuerligt med
projektet. Den sjätte november 2003 bildades studentföreningen
VilleValla Pub. Vi var då sex personer och arbetet kretsade mest kring
själva utformningen av publokalen, kontakter med kommunen och
myndigheter men naturligtvis även finansieringen.</p> <p>I och med att
föreningen, för att säkerställa att puben riktar sig till alla
studenter, inte är knuten till vare sig kårer, nationer eller andra
studentföreningar var det uppenbart att finansieringen var tvungen att
ske genom bidrag och sponsring. Att få sponsring till studentprojekt
var då inte så lätt men vi gjorde ett tappert försök att få medel från
fonder och stiftelser som vi bedömde skulle vara mindre
konjunkturkänsliga. Länge såg det ljust ut och vi trodde att vi skulle
få halva vår finansiering den vägen, men tyvärr blev det inte så.</p>
<p>En stor hjälte för finansieringen av VilleValla är Stig Holm, VD
Tekniska verken i Linköping. Han visade, i en tid då många sade sig
stödja projektet men få aktivt visade det i handling, att Tekniska
verken är genuint intresserade av oss studenter och att de stödjer
studentinitiativ som är bra för Linköping. Utan Stig och utan stödet
av Simon Bergwall på Studentbostäder hade VilleValla Pub aldrig blivit
verklighet.</p> <p>Sanningen är faktiskt den, att i början av sommaren
då ombyggnaden av puben var planerad att börja så hade vi bara hälften
av de beräknade kostnaderna med säkerhet täckta. Efter en liten
försening togs dock beslutet att börja bygga och det som en gång var
en bostadsvilla och därefter kontorslokal började nu förvandlas till
en pub. Föreningen hade under våren växt till att omfatta cirka 15
personer och ytterligare hade strömmat till för att hjälpa till. Tack
vara dessa hårt arbetande hjältar så växte VilleValla Pub så sakteliga
fram.</p> <p>Ganska snart visade det sig att väldigt många
Linköpingsstudenter har väldigt få att tacka för väldigt mycket. Ett
litet gäng bestående av Anders, Josefin, Johan och Tommy har
bokstavligen offrat en sommar och lagt ned sina själar i bygget. De
två sistnämnda är dessutom upphovsmännen till den numera smått
legendariska bardisken.</p> <p>En och en halv månad för sent och efter
en del svackor, strul och intensivt jobb på sluttampen så slog vi, den
andra oktober 2004, stolt upp dörrarna till Linköpings fjärde
studentpub. Hit kan du komma för en bit mat, en god öl eller ett
kanske ett parti backgammon. Vi hoppas att se dig många kvällar
framöver</p>
<br/>
<br/>Varma hälsningar
<br/>&nbsp;&nbsp;Pierre Reldin
</div>
`;
