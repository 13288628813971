/* eslint max-classes-per-file: ["error", 2] */
import './CardModal.scss';

import {Button, Grid, Modal, useMediaQuery} from '@mui/material';

import PageTextCard from './PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';
import {useTheme} from '@mui/material/styles';


const DesktopCardModal = function DesktopCardModal(props) {
  const {header, children, onClose, ...otherProps} = props;
  return (
    <Modal onClose={onClose} {...otherProps}>
      <div className="modal--desktop">
        <PageTextCard header={header}>
          <Grid item xs={12}className="modalContent">
            {children}
          </Grid>
          <div className="pageTextCardButton">
            <Button
              variant="contained"
              onClick={onClose}
            >
              Stäng
            </Button>
          </div>
        </PageTextCard>
      </div>
    </Modal>
  );
};

DesktopCardModal.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string,
  onClose: PropTypes.function,
};


const MobileCardModal =
  function MobileCardModal(props) {
    const {header, children, onClose, ...otherProps} = props;

    return (
      <Modal onClose={onClose} {...otherProps}>
        <div className="modal--mobile">
          <PageTextCard header={header} >
            <Grid item xs={12} className="modalContent">
              {children}
            </Grid>
            <div className="pageTextCardButton">
              <Button
                variant="contained"
                onClick={onClose}
              >
                Stäng
              </Button>
            </div>
          </PageTextCard>
        </div>
      </Modal>
    );
  };

MobileCardModal.propTypes = {
  children: PropTypes.any,
  header: PropTypes,
  onClose: PropTypes.function,
};


const CardModal = (props) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  if (desktop) {
    return (
      <DesktopCardModal {...props}/>
    );
  }
  return (
    <MobileCardModal {...props}/>
  );
};

export default CardModal;
