import api from 'App/api';

export const LOAD_BOOKING_MENU_BEGIN = 'LOAD_BOOKING_MENU_BEGIN';
export const LOAD_BOOKING_MENU_SUCCESS = 'LOAD_BOOKING_MENU_SUCESS';
export const LOAD_BOOKING_MENU_FAIL = 'LOAD_BOOKING_MENU_FAIL';

export const loadBookingMenuBegin = () => ({
  type: LOAD_BOOKING_MENU_BEGIN,
});

export const loadBookingMenuSuccess = (bookingMenuObj) => ({
  bookingMenuObj,
  type: LOAD_BOOKING_MENU_SUCCESS,
});

export const loadBookingMenuFail = (error) => ({
  error,
  type: LOAD_BOOKING_MENU_FAIL,
});

const convertDataToMenu = (data) => {
  const bookingMenuId = 1;
  const bookingMenu = {[bookingMenuId]:
    {
      hotOrNot: false,
      items: [],
      titles: {
        en: 'Booking Menu',
        sv: 'Bokningsmat',
      },
    }};

  data.bokningsmat.forEach((food) => {
    bookingMenu[bookingMenuId].items.push({
      desc: food.descr,
      img_url: food.img_url,
      name: food.name,
      price: food.price,
    });
  });

  return bookingMenu;
};

export const fetchBookingFoodMenuData = () => (dispatch) => {
  dispatch(loadBookingMenuBegin());
  api.get('booking_foods').
      then((res) => res.data).
      then((json) => {
        const bookingMenuObj = {bookingMenu: convertDataToMenu(json)};
        dispatch(loadBookingMenuSuccess(bookingMenuObj));
      }).
      catch((err) => {
        dispatch(loadBookingMenuFail(err));
      });
};
