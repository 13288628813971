import './OmOss.scss';

import {Button, CardContent, CardMedia, Grid, Typography} from '@mui/material';

import CardModal from '@Components/CardModal';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';

import {historien} from './historien';
import omOssData from './OmOss.json';

const AboutUsCard = (props) => {
  const {header, children} = props;
  return (
    <Grid item xs={12} >
      <PageTextCard header={header} >
        <Grid item>
          {children}
        </Grid>
      </PageTextCard>
    </Grid>
  );
};

AboutUsCard.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
};

const VilleVallaPub = () => <AboutUsCard header="VilleValla Pub">
  <CardMedia
    component="img"
    height="auto"
    alt="Bild på driftgruppen"
    src="https://res.cloudinary.com/hsocx4wtk/image/upload/f_auto,q_auto/elwg4lpeuj6kjnidp4as"
  />
  <CardContent>
    <Typography variant="body1" gutterBottom>
      Vi vill hälsa alla gamla och nya studenter välkomna till VilleValla Pub.
      Vi är en av Sveriges grymmaste studentpubar som har specialiserats oss på
      öl från när och fjärran. För tillfället har vi ett gediget utbud av
      ölflaskor men vår kronjuvel är vårt nya tappsystem på 20 tappar. Tack vare
      detta tappsystem så kopplas det på nya spännande ölsorter nästan varje
      kväll.
      <br/><br/>
      Så ta en sväng förbi våra lokaler på
      <a href="https://maps.google.com/?q=Vallav%C3%A4gen+4C&entry=gmail&source=g"
        target="_blank" rel="noreferrer"> Vallavägen 4C </a>
      och avnjut någon av våra rätter från á la carte menyn och testa något av
      våra grymma brädspel.
    </Typography>
  </CardContent>
</AboutUsCard>;

const Historien = (props) => <AboutUsCard header="Historien">
  <Typography variant="body1">
    {omOssData.Historien}
  </Typography>
  <div className="pageTextCardButton">
    <Button variant="outlined" onClick={props.showModal}>
      Läs hela historien
    </Button>
  </div>
</AboutUsCard>;

Historien.propTypes = {
  showModal: PropTypes.func,
};

const Luntan = () => <AboutUsCard header="Luntan">
  <Typography variant="body1" gutterBottom>
    I början av projektet sammaställdes ett dokument för att göra upp
    riktlinjer och visioner. Den intresserade kan läsa allt i <a
      href="https://old.villevallapub.se/documents/luntan121.pdf"
      target="_blank" rel="noreferrer">Luntan</a>.
  </Typography>
</AboutUsCard>;

const Byggnationen = () => <AboutUsCard header="Byggnationen">
  <Typography variant="body1">
    {omOssData.Byggnationen.map((text, index) => <React.Fragment
      key={index}> {text} <br/><br/> </React.Fragment>)}
  </Typography>
</AboutUsCard>;

export default class OmOss extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalOpen: false};
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  static getHeaderUrl(isAuthenticated) {
    if (isAuthenticated) {
      return 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_8000_scaled_n9fbob.jpg';
    }
    return 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184254/IMG_8000_scaled_n9fbob.jpg';
  }

  hideModal = () => {
    this.setState({modalOpen: false});
  };

  showModal = () => {
    this.setState({modalOpen: true});
  };

  render() {
    return (
      <React.Fragment>
        <CardModal open={this.state.modalOpen} onClose={this.hideModal}
          header="Historien om VilleValla Pub">
          <Typography variant="body1"
            dangerouslySetInnerHTML={{__html: historien}} />
        </CardModal>

        <VilleVallaPub />

        <Historien showModal={this.showModal} />

        <Byggnationen />

        {/*
        <AboutUsCard header="Mediabevakning">
          <Typography variant="body1" />
        </AboutUsCard>
        */}

        <Luntan />
      </React.Fragment>
    );
  }
}
