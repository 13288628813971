import {
  LOAD_MENU_BEGIN,
  LOAD_MENU_FAIL,
  LOAD_MENU_SUCCESS,
} from '@Actions/menu.js';

const initialMenuState = {
  error: false,
  errorMsg: null,
  fetched: false,
  fetching: false,
  menuReducer:
  {
    burgers: {
      hotOrNot: true,
      items: [
        {
          desc: 'A birger filled with food',
          img: 'https://p0.pikist.com/photos/485/68/figure-person-horror-food-diet-china-cooking-kitchen-sushi.jpg',
          name: 'Birger',
          price: '69',
        },
        {
          desc: 'A birger filled with monthly food',
          img: 'https://p0.pikist.com/photos/485/68/figure-person-horror-food-diet-china-cooking-kitchen-sushi.jpg',
          name: 'Manades Borgar',
          price: '420',
        },
      ],
      titles: {
        en: 'Burgers',
        sv: 'Burgare',
      },
    },
    sandwiches: {
      hotOrNot: true,
      items: [
        {
          desc: 'A mack filled with skink',
          img: 'https://p0.pikist.com/photos/485/68/figure-person-horror-food-diet-china-cooking-kitchen-sushi.jpg',
          name: 'Skinkmacka',
          price: '15',
        },
        {
          // eslint-disable-next-line max-len
          desc: 'We are swedish, and instead of peking duck,we eeat buttered goose',
          img: 'https://cdn.pixabay.com/photo/2018/06/08/14/46/figure-3462463_960_720.jpg',
          name: 'Smörgås',
          price: '42',
        },
      ],
      titles: {
        en: 'Sandwiches',
        sv: 'SMörgare',
      },
    },
    snack: {
      hotOrNot: false,
      items: [
        {
          desc: 'Kastade i BBQ-sås och sirachasås, serveras med sås',
          img: 'https://p0.pikist.com/photos/485/68/figure-person-horror-food-diet-china-cooking-kitchen-sushi.jpg',
          name: 'Hot wings',
          price: '35',
        },
        {
          desc: 'Serveras med colasås',
          img: 'https://p0.pikist.com/photos/485/68/figure-person-horror-food-diet-china-cooking-kitchen-sushi.jpg',
          name: 'Churros',
          price: '30',
        },
        {
          desc: 'Serveras med dipp',
          img: 'https://p0.pikist.com/photos/485/68/figure-person-horror-food-diet-china-cooking-kitchen-sushi.jpg',
          name: 'Pommes',
          price: '30',
        },
      ],
      titles: {
        en: 'Snacks',
        sv: 'Gfefert',
      },
    },
  },
};

// eslint-disable-next-line default-param-last
export default function menuReducer(state = {...initialMenuState}, action) {
  switch (action.type) {
    case LOAD_MENU_BEGIN:
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    case LOAD_MENU_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {menu} = action;
      return {
        ...state,
        error: false,
        fetched: true,
        fetching: false,
        menu,
      };
    case LOAD_MENU_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {error} = action;
      return {
        ...state,
        error: true,
        errorMsg: error,
        fetched: true,
        fetching: false,
      };
    default:
      return state;
  }
}
