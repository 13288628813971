import api from 'App/api';


export const LOAD_EVENTS_BEGIN = 'LOAD_EVENTS_BEGIN';
export const LOAD_EVENT_SUCCESS = 'LOAD_EVENT_SUCCESS';
export const LOAD_EVENT_FAIL = 'LOAD_EVENT_FAIL';


export const loadEventsBegin = () => ({
  type: LOAD_EVENTS_BEGIN,
});


export const loadEventsSuccess = (events) => ({
  events,
  type: LOAD_EVENT_SUCCESS,
});


export const loadEventsFail = (error) => ({
  error,
  type: LOAD_EVENT_FAIL,
});


export const fetchEventsData = () => (dispatch) => {
  dispatch(loadEventsBegin());
  api.get('external_calendar').
      then((res) => res.data).
      then((json) => {
        const events = json;
        const eventsObj = events;
        dispatch(loadEventsSuccess(eventsObj));
      }).
      catch((err) => {
        dispatch(loadEventsFail(err));
      });
};
