import messagesGeneralEn from './General/en.json';
import messagesHomeEn from './Home/en.json';
import messagesOpeningHoursEn from './Components/OpeningHours/en.json';
import messagesWorkhereEn from './WorkHere/en.json';

export const messagesEn = {
  ...messagesHomeEn,
  ...messagesOpeningHoursEn,
  ...messagesGeneralEn,
  ...messagesWorkhereEn,
};
