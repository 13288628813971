import axios from 'axios';

const baseUrl = process.env.NODE_ENV === 'development' ?
  'http://localhost:3001/api/' :
  'https://old.villevallapub.se/api/';

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response,
    async (error) => {
      const originalRequest = error.config;
      // eslint-disable-next-line no-underscore-dangle, no-magic-numbers
      if (error.response.status === 401 && !originalRequest._retry) {
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;

        try {
          // Attempt to refresh token
          const refreshResponse = await axios.get('auth/refresh');
          const {accessToken} = refreshResponse.data;

          localStorage.setItem('accessToken', accessToken);

          // Update the header and retry the original request
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          // Clear tokens and redirect to login
          localStorage.removeItem('accessToken');
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      }
      // For other types of errors
      return Promise.reject(error);
    });

export default api;
