import {
  LOAD_PUBLICMESSAGES_BEGIN,
  LOAD_PUBLICMESSAGES_FAIL,
  LOAD_PUBLICMESSAGES_SUCCESS,
} from '@Actions/messages.js';

const initialMessagesStates = {
  publicMessages: {
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
    publicMessages: {},
  },
};

// eslint-disable-next-line default-param-last, max-len
export default function messagesReducer(state = {...initialMessagesStates}, action) {
  switch (action.type) {
    case LOAD_PUBLICMESSAGES_BEGIN:
      return {
        ...state,
        publicMessages: {
          ...state.publicMessages,
          fetched: false,
          fetching: true,
        },
      };
    case LOAD_PUBLICMESSAGES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {publicMessagesObj} = action;
      return {
        ...state,
        publicMessages: {
          ...state.publicMessages,
          error: false,
          fetched: true,
          fetching: false,
          publicMessages: publicMessagesObj.publicMessages,
        },
      };
    case LOAD_PUBLICMESSAGES_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {publicMessagesError} = action;
      return {
        ...state,
        publicMessages: {
          ...state.publicMessages,
          error: true,
          errorMsg: publicMessagesError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
