/* eslint-disable max-classes-per-file */
import './Drinkmenu.scss';

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import React from 'react';

import {connect} from 'react-redux';
import {fetchDrinkMenuData} from '@Actions/drinks';


class DrinkmenuRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {expanded: false};
  }

  render() {
    const {data} = this.props;
    return (
      <React.Fragment>
        <ListItem
          className="drink-menu-row"
          button
          onClick={() => this.setState({expanded: !this.state.expanded})}
        >
          <Grid container>
            <Grid item xs={2} className="drink-menu-cell--main">
              <img className="drink-menu-card-img" src={data.image} alt=""/>
            </Grid>
            <Grid item xs={4} className="drink-menu-cell--main" align="left">
              <div className="drink-menu-title">
                <div>
                  {data.name} -
                </div>
                <div>
                  {data.brewery}
                </div>
              </div>
            </Grid>
            <Grid itemxs={3}
              className="drink-menu-cell--main" align="left"
            >{data.price}</Grid>
            <Grid item xs={2}
              className="drink-menu-cell--main" align="left"
            >{data.abv}</Grid>
            <Grid item xs={1}
              className="drink-menu-cell--main" align="right"
            >
              <KeyboardArrowUpIcon className={`arrow${this.state.expanded ?
' flipped' :
''}`}/>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                HELLO
              </Collapse>
            </Grid>
          </Grid>
        </ListItem>
        <Divider className="drink-menu-divider"/>
      </React.Fragment>
    );
  }
}

DrinkmenuRow.propTypes = {
  data: PropTypes.object,
};

class DrinkMenuCategory extends React.Component {
  render() {
    // eslint-disable-next-line max-len
    const drinks = this.props.drinks.map((row) => <DrinkmenuRow key={row.name} data={row} />);
    return (
      <React.Fragment>
        <ListItem
          className="drink-menu-row--category"
          style={{top: this.props.scroll.isScrolled ?
'0' :
`${String(this.props.scroll.scrollHeight)}px`}}
        >
          <Grid container>
            <Grid item xs={4}>
              <Divider/>
            </Grid>
            <Grid item xs={4} className="drink-menu-category">
              <b>{this.props.category}</b>
            </Grid>
            <Grid item xs={4}>
              <Divider/>
            </Grid>
          </Grid>
        </ListItem>
        {drinks}
      </React.Fragment>
    );
  }
}

DrinkMenuCategory.propTypes = {
  category: PropTypes.string,
  drinks: PropTypes.array,
  scroll: PropTypes.object,
};

class Drinkmenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {expanded: true};
  }

  render() {
    // eslint-disable-next-line no-magic-numbers
    const rows = Array(20).fill(this.props.drinks.drinks.light[1]);
    // eslint-disable-next-line no-magic-numbers
    const rows2 = Array(20).fill(this.props.drinks.drinks.light[0]);
    return (
      <React.Fragment>
        <List className="drink-menu">
          <ListItem
            className={'drink-menu-row--header'}
            style={{top: this.props.scroll.isScrolled ?
'50px' :
    // eslint-disable-next-line no-magic-numbers
`${this.props.scroll.scrollHeight + 50}px`}}
          >
            <Grid container>
              <Grid item xs={2}
                className="drink-menu-cell"/>
              <Grid item xs={4}
                className="drink-menu-cell" align="left"><b>Name</b></Grid>
              <Grid item xs={3}
                className="drink-menu-cell" align="left"><b>Price</b></Grid>
              <Grid item xs={2}
                className="drink-menu-cell" align="left"><b>Abv</b></Grid>
              <Grid item xs={1}
                className="drink-menu-cell" align="right"/>
            </Grid>
          </ListItem>
          <DrinkMenuCategory
            category="ljusa" drinks={rows} scroll={this.props.scroll}/>
          <DrinkMenuCategory
            category="morka" drinks={rows2} scroll={this.props.scroll}/>
        </List>
      </React.Fragment>
    );
  }
}

Drinkmenu.propTypes = {
  drinks: PropTypes.object,
  fetchFoodMenuData: PropTypes.func,
  scroll: PropTypes.object,
};

const mapStateToProps = (state) => ({
  drinks: state.drinksReducer,
  scroll: state.scrollReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFoodMenuData: () => dispatch(fetchDrinkMenuData()),
});

export default connect(mapStateToProps,
    mapDispatchToProps)(Drinkmenu);
