import './ContentLayout.scss';

import {
  StyledEngineProvider,
  useTheme,
} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ContentLayout(props) {
  const theme = useTheme();
  const mobile = !useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <StyledEngineProvider injectFirst>
      <div
        className="content-layout"
      >
        <Paper
          elevation={mobile ?
              // eslint-disable-next-line no-magic-numbers
              0 :
              // eslint-disable-next-line no-magic-numbers
              4}
          square={mobile}
        >
          <Grid
            className="content-layout-content"
            container
            alignItems="flex-start"
            spacing={1}
            direction="row"
          >
            {props.children}
          </Grid>
        </Paper>
      </div>
    </StyledEngineProvider>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.node,
};
