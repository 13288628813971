import './AccountMenu.scss';

import {Avatar, Divider, IconButton, Link, Menu, MenuItem} from '@mui/material';
import React, {useState} from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';

import {useAuth} from '@Hooks/AuthContext';

const AccountMenuItem = (props) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  <MenuItem component={RouterLink} to={props.to} onClick={props.onClick}>
    <ListItemIcon> {props.icon} </ListItemIcon>
    {props.text}
  </MenuItem>;

AccountMenuItem.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isOptional,
};

// eslint-disable-next-line max-lines-per-function
const AccountMenu = () => {
  const {currentUser, isAuthenticated} = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    const currentLocation = JSON.stringify(window.location.pathname);
    sessionStorage.setItem('preLoginRoute', currentLocation);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="account of current user" color="inherit"
        aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu}>
        <AccountCircle />
      </IconButton>
      <Menu id="menu-appbar" anchorEl={anchorEl} keepMounted
        open={open} onClose={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar className="accountCircle" /> {isAuthenticated ?
          // eslint-disable-next-line prefer-template
          'Hej, ' + currentUser + '!' :
          'Ej inloggad'}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon> <LoginIcon /> </ListItemIcon>
          <Link href="https://old.villevallapub.se/login" underline="none" >
            Logga in (Gamla interna)
          </Link>
        </MenuItem>
        {isAuthenticated ?
        <AccountMenuItem to="/logout" text="Logga ut (Nya interna)"
          icon={<LogoutIcon />} onClick={handleClose} /> :
          <AccountMenuItem to="/login" text="Logga in (Nya interna)"
            icon={<LoginIcon />} onClick={handleClose} />}
      </Menu>
    </div>
  );
};

export default AccountMenu;
