import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import NoSpacingGrid from './NoSpacingGrid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

const getOpenDays = (intl, openingHours) => [
  {'name': intl.formatMessage({
    defaultMessage: 'Tisdag',
    id: 'Day.tue',
  }),
  'time': openingHours.hours.en.Tuesday},
  {'name': intl.formatMessage({
    defaultMessage: 'Onsdag',
    id: 'Day.wed',
  }),
  'time': openingHours.hours.en.Wednesday},
  {'name': intl.formatMessage({
    defaultMessage: 'Torsdag',
    id: 'Day.thur',
  }),
  'time': openingHours.hours.en.Thursday},
  {'name': intl.formatMessage({
    defaultMessage: 'Fredag',
    id: 'Day.fri',
  }),

  'time': openingHours.hours.en.Friday},
  {'name': intl.formatMessage({
    defaultMessage: 'Lördag',
    id: 'Day.sat',
  }),
  'time': openingHours.hours.en.Saturday},
];

const OpeningHoursItem = (props) => <ListItem>
  <div className="opening-hours-item">
    <Typography variant="body1">
      <b>{props.day}</b>
    </Typography>
    <Typography variant="body1">
      {props.time}
    </Typography>
  </div>
</ListItem>;

OpeningHoursItem.propTypes = {
  day: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default class OpeningHoursCard extends React.Component {
  render() {
    const {intl, openingHours} = this.props;
    const openDays = getOpenDays(intl,
        openingHours);
    return (
      <>
        <NoSpacingGrid
          spacing={1}
          left
          right
          bottom
          width="100%"
        >
          <List>
            {openingHours.hours.sv.msg ?
              <ListItem>
                <Alert severity="info">
                  {openingHours.hours.sv.msg}
                </Alert>
              </ListItem> :
              <>
              </>
            }
            {openDays.map((day) => <>
              <Divider component="li" />
              <OpeningHoursItem
                day={day.name}
                time={day.time}
                key={day.name}
              />
            </>)}
          </List>
        </NoSpacingGrid>
      </>
    );
  }
}

OpeningHoursCard.propTypes = {
  intl: PropTypes.object.isRequired,
  openingHours: PropTypes.object.isRequired,
};
