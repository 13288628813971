import './App.scss';

import {ThemeProvider, createTheme} from '@mui/material/styles';
import ViewRouter,
{exactRouteObjects, externalRouteObjects} from './ViewRouter';

import {IntlProvider} from 'react-intl';
import Layout from './Layout/Layout';
import PropTypes from 'prop-types';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import _theme from '@Styling/_theme.module.scss';
import {connect} from 'react-redux';

import {messagesEn} from './Translations/en.js';
import {messagesSv} from './Translations/sv.js';

const theme = createTheme({
  palette: {
    primary: {
      main: _theme.primaryColor,
    },
    secondary: {
      main: _theme.secondaryColor,
    },
  },
  typography: {
    fontFamily: [
      _theme.primaryFont,
      _theme.primaryFontFamily,
    ].join(','),
  },
});

const messages = {
  'en': messagesEn,
  'sv': messagesSv,
};

const navbarRouteObjects = externalRouteObjects;

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <IntlProvider
          locale={this.props.lang}
          messages={messages[this.props.lang]}
        >
          <Router>
            <div className="app">
              <Layout exactRouteObjects={exactRouteObjects}
                navbarRouteObjects={navbarRouteObjects}
              >
                <ViewRouter />
              </Layout>
            </div>
          </Router>
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  lang: PropTypes.string,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
});

export default connect(mapStateToProps)(App);
