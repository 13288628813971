import './DrinkmenuPage.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularLoader from '@Components/CircularLoader';
import DownloadIcon from '@mui/icons-material/Download';
// eslint-disable-next-line no-unused-vars
import Drinkmenu from './Drinkmenu';
import Grid from '@mui/material/Grid';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';


import {connect} from 'react-redux';
import {fetchDrinkMenuData} from '@Actions/drinks';

class DrinkMenuPage extends React.Component {
  static getHeaderUrl() {
    return 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1640304501/runt_bord_qxigll.jpg';
  }

  render() {
    const {drinks, getDrinkMenu} = this.props;

    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={12} >
          <PageTextCard header={'Tappmeny'} elevation={3} >
            <Grid item xs={12} >
              <CircularLoader reducer={drinks} action={getDrinkMenu} >
                <Box component="img" src={drinks.draftMenuUrl} id="draftMenu" />
              </CircularLoader>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button variant="contained" href="https://old.villevallapub.se/menu/vvp-olmeny.pdf" endIcon={<DownloadIcon/>}>
                Ladda ner flaskmeny
              </Button>
            </Grid>
          </PageTextCard>
        </Grid>
        {/*
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <PageTextCard
            header={'Ölmeny'}
            elevation={3}
            className='drink-menu-page-card'
          >
            <Grid
              item
              xs={12}
            >
              <Drinkmenu/>
            </Grid>
          </PageTextCard>
        </Grid>
        */}

      </React.Fragment>
    );
  }
}

DrinkMenuPage.propTypes = {
  drinks: PropTypes.object,
  getDrinkMenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  drinks: state.drinksReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getDrinkMenu: () => dispatch(fetchDrinkMenuData()),
});

export default connect(mapStateToProps,
    mapDispatchToProps)(DrinkMenuPage);
