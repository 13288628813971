import './Home.scss';
import CalendarComponent from './CalendarComponent';
import CircularLoader from '@Components/CircularLoader';
import Grid from '@mui/material/Grid';
import OpeningHours from './OpeningHours';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import WIPInfoComponent from './WIPInfoComponent';
import {connect} from 'react-redux';
import {fetchPublicMessages} from '@Actions/messages';
import {injectIntl} from 'react-intl';

const infoCards = [
  {
    message:
    <WIPInfoComponent />,
    name: 'VilleVallas nya sida',
    size: {
      md: 4,
      sm: 6,
      xs: 12,
    },
  },
  {
    message: <CalendarComponent className = "calendarCard"/>,

    name: 'VilleValla kalender',
    size: {
      md: 4,
      sm: 6,
      xs: 12,
    },
  },

  {
    message: <OpeningHours />,
    name: 'OpeningHours',
    size: {
      md: 4,
      sm: 6,
      xs: 12,
    },
  },


];

const formatPublicMessages = (publicMessages) => {
  const messagesEn = [];
  const messagesSv = [];

  publicMessages.forEach((message) => {
    messagesEn[message.id] = {
      caption: message.caption_eng,
      externally_visible: message.externally_visible,
      forever_visible: message.forever_visible,
      message: message.message_eng,
      placement: message.placement,
      prio: message.prio,
      published: message.published,
      visible_until: message.visible_until,
    };
    messagesSv[message.id] = {
      caption: message.caption,
      externally_visible: message.externally_visible,
      forever_visible: message.forever_visible,
      message: message.message,
      placement: message.placement,
      prio: message.prio,
      published: message.published,
      visible_until: message.visible_until,
    };
  });
  return {
    'en': messagesEn,
    'sv': messagesSv,
  };
};

const PublicMessagesCard = (props) => {
  const {lang, publicMessagesJson} = props;
  const publicMessagesLang = formatPublicMessages(publicMessagesJson);
  const publicMessages = publicMessagesLang[lang];

  return (
    <>
      {publicMessages.map((info) => <Grid
        item xs={12} sm={6} md={4} key={info.caption} >
        <PageTextCard header={info.caption} elevation={3}>
          <Grid item xs={12}>
            <Typography dangerouslySetInnerHTML={{__html: info.message}} />
          </Grid>
        </PageTextCard>
      </Grid>)}
    </>
  );
};

PublicMessagesCard.propTypes = {
  lang: PropTypes.string,
  publicMessagesJson: PropTypes.array,
};


class Home extends React.Component {
  state = {
    open: false,
  };

  static getHeaderUrl(authenticated) {
    if (authenticated) {
      return 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184255/IMG_7984_scaled_sqtzog.jpg';
    }
    return 'https://res.cloudinary.com/hsocx4wtk/image/upload/v1708184255/IMG_7984_scaled_sqtzog.jpg';
  }

  handleClose = (event) => {
    this.setState({open: false});
  };

  componentDidMount = () => {
    const {location} = this.props;
    if (location.state && location.state.open) {
      this.setState({
        open: location.state.open,
      });
    }
  };

  render() {
    const {intl, lang, publicMessages} = this.props;
    return (
      <React.Fragment>
        <CircularLoader
          reducer={publicMessages}
          action={this.props.fetchPublicMessages}
        >
          <PublicMessagesCard intl={intl} lang={lang}
            publicMessagesJson={publicMessages.publicMessages}
          />
        </CircularLoader>
        {
          infoCards.map((card) => <Grid item
            xs={card.size.xs} sm={card.size.sm} md={card.size.md} key={card}>
            {card.message}
          </Grid>)
        }
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  fetchPublicMessages: PropTypes.func,
  intl: PropTypes.object,
  lang: PropTypes.string,
  location: PropTypes.object,
  publicMessages: PropTypes.object,
};

const mapStateToProps = (state) => ({
  lang: state.langReducer.lang,
  publicMessages: state.messagesReducer.publicMessages,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPublicMessages: () => dispatch(fetchPublicMessages()),
});

export default connect(mapStateToProps,
    mapDispatchToProps)(injectIntl(Home));
