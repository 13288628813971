import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

const CircularLoader = function CircularLoader(props) {
  const {reducer, action, children} = props;
  const loaded = !reducer.fetching && reducer.fetched;
  if (!loaded && !reducer.fetching) {
    action();
  }
  return (
    // eslint-disable-next-line no-negated-condition, no-nested-ternary
    !loaded ?
      <div className="centered-loader">
        <CircularProgress />
      </div> :
    // eslint-disable-next-line no-negated-condition
    !reducer.error ?
      <> {children} </> :
      <> {reducer.error} </>
  );
};

CircularLoader.propTypes = {
  action: PropTypes.func,
  children: PropTypes.object,
  reducer: PropTypes.object,
};

export default CircularLoader;
