import './OpeningHours.scss';

import {FormattedMessage, injectIntl} from 'react-intl';

import CircularLoader from '@Components/CircularLoader';
import OpeningHoursCard from '@Components/OpeningHoursCard';
import PageTextCard from '@Components/PageTextCard';
import PropTypes from 'prop-types';
import React from 'react';


import {connect} from 'react-redux';
import {fetchOpenHours} from '@Actions/openHours';

class OpeningHours extends React.Component {
  render() {
    const {intl, openingHours} = this.props;

    const loaded = !openingHours.fetching && openingHours.fetched;
    if (!loaded && !openingHours.fetching) {
      this.props.fetchOpenHours();
    }
    return (
      <React.Fragment>
        <PageTextCard
          header={
            <FormattedMessage id="openingHours.hours"
              defaultMessage="Öppetider"
            />
          }
          className="opening-hours-component"
          elevation={3}
        >
          <CircularLoader
            action={this.props.fetchOpenHours} reducer={openingHours}>
            <OpeningHoursCard intl={intl} openingHours={openingHours} />
          </CircularLoader>
        </PageTextCard>
      </React.Fragment>
    );
  }
}

OpeningHours.propTypes = {
  fetchOpenHours: PropTypes.func,
  intl: PropTypes.object,
  openingHours: PropTypes.object,
};

const mapStateToProps = (state) => ({
  openingHours: state.openHoursReducer.openingHours,
});

const mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    fetchOpenHours: () => dispatch(fetchOpenHours()),
  };
};

export default connect(mapStateToProps,
    mapDispatchToProps)(injectIntl(OpeningHours));
