import api from 'App/api';

export const LOAD_MENU_BEGIN = 'LOAD_MENU_BEGIN';
export const LOAD_MENU_SUCCESS = 'LOAD_MENU_SUCESS';
export const LOAD_MENU_FAIL = 'LOAD_MENU_FAIL';

export const loadMenuBegin = () => ({
  type: LOAD_MENU_BEGIN,
});

export const loadMenuSuccess = (menu) => ({
  menu,
  type: LOAD_MENU_SUCCESS,
});

export const loadMenuFail = (error) => ({
  error,
  type: LOAD_MENU_FAIL,
});

const convertDataToMenu = (data) => {
  const menu = {};
  data.categories.forEach((cat) => {
    menu[cat.id] = {
      hotOrNot: !(cat.name === 'Snacks' || cat.name === 'Efterrätt'),
      items: [],
      titles: {
        en: cat.name,
        sv: cat.name,
      },
    };
  });
  data.food.forEach((food) => {
    menu[food.food_category_id].items.push({
      desc: food.descr,
      img_url: food.img_url,
      name: food.name,
      price: food.price,
    });
  });
  return menu;
};

export const fetchFoodMenuData = () => (dispatch) => {
  dispatch(loadMenuBegin());
  api.get('food_menu').
      then((res) => {
        dispatch(loadMenuSuccess(convertDataToMenu(res.data)));
      }).
      catch((err) => {
        dispatch(loadMenuFail(err));
      });
};
