import api from 'App/api';

export const LOAD_OPENHOURS_BEGIN = 'LOAD_OPENHOURS_BEGIN';
export const LOAD_OPENHOURS_SUCCESS = 'LOAD_OPENHOURS_SUCCESS';
export const LOAD_OPENHOURS_FAIL = 'LOAD_OPENHOURS_FAIL';

export const loadOpenHoursBegin = () => ({
  type: LOAD_OPENHOURS_BEGIN,
});

export const loadOpenHoursSuccess = (openingHoursObj) => ({
  openingHoursObj,
  type: LOAD_OPENHOURS_SUCCESS,
});

export const loadOpenHoursFail = (error) => ({
  error,
  type: LOAD_OPENHOURS_FAIL,
});

export const fetchOpenHours = () => (dispatch) => {
  dispatch(loadOpenHoursBegin());
  api.get('opening_hours').
      then((res) => res.data).
      then((json) => {
        const openingHoursObj = {hours: json};
        dispatch(loadOpenHoursSuccess(openingHoursObj));
      }).
      catch((err) => {
        dispatch(loadOpenHoursFail(err));
      });
};
