import {Route, Switch} from 'react-router-dom';

import Bokning from './Views/Bokning/Bokning';
import Drinkmenu from './Views/Drinkmenu/DrinkmenuPage';
import Home from './Views/Home/Home';
import Kontakt from './Views/Kontakt/Kontakt';
import Login from './Views/Login/Login';
import Logout from './Views/Login/Logout';
import Matmeny from './Views/Matmeny/Matmeny';
import OmOss from './Views/OmOss/OmOss';
import React from 'react';
import WorkHere from './Views/WorkHere/WorkHere';

export const externalRouteObjects = [
  {
    Component: Home,
    name: 'Hem',
    url: '/',
  },
  {
    Component: OmOss,
    name: 'Om oss',
    url: '/om_oss',
  },
  {
    Component: Bokning,
    name: 'Bokning',
    url: '/bokning',
  },
  {
    Component: Kontakt,
    name: 'Kontakt',
    url: '/kontakt',
  },
  {
    Component: WorkHere,
    name: 'Jobba här',
    url: '/WorkHere',
  },

  {
    Component: Matmeny,
    name: 'Matmeny',
    url: '/meny/mat',
  },
  {
    Component: Drinkmenu,
    name: 'Dryckesmeny',
    url: '/menu/beverages',
  },
];

export const internalRouteObjects = [
];

export const hiddenRouteObjects = [
  {
    Component: Login,
    name: 'Login',
    url: '/login',
  },
  {
    Component: Logout,
    name: 'Logout',
    url: '/logout',
  },
];

export const exactRouteObjects =
  [...externalRouteObjects, ...hiddenRouteObjects];

export const topLevelRouteObjects = [
];

export const allRouteObjects = [...exactRouteObjects, ...topLevelRouteObjects];

export default class ViewRouter extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const exactRoutes = exactRouteObjects.map(({url, Component}) => <Route
      exact
      key={url}
      path={url}
      component={Component}
    />);
    const topLevelRoutes = topLevelRouteObjects.map(({url, Component}) => <Route
      key={url}
      path={url}
      component={Component}
    />);
    return (
      <Switch>
        {exactRoutes}
        {topLevelRoutes}
      </Switch>
    );
  }
}
