import './ScrollTop.scss';

import {Fab, Zoom, useScrollTrigger} from '@mui/material';
import React, {useCallback} from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/*
 * Scrolls the viewport to the top of the website
 *
 */

export default function ScrollTop() {
  const trigger = useScrollTrigger({
    threshold: 100,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  });

  return (
    <React.Fragment>
      <Zoom in={trigger}>
        <div onClick={scrollToTop} role="presentation" className="scrollTop">
          <Fab color="primary" size="medium" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      </Zoom>
    </React.Fragment>
  );
}
