import './FoodMenyAccordion.scss';

import {Card, CardContent} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';

const FoodItems = (props) => props.data.map((item) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  <Card className="width-100 menu-item" key={item.name}>
    {/* <img
          className="menu-card-img"
          src={item.img_url}
          alt="green iguana"
        />
        */}
    <CardContent
      className="menu-card-content"
    >
      <Typography variant="body1">
        {item.name}
      </Typography>
      {!props.hidePrice &&
      <Typography
        variant="caption"
        color="gray"
        component="p"
        className="menu-card-price"
      >
        {item.price} kr
      </Typography>
      }
      <Typography variant="body2" className="menu-card-desc">
        {item.desc}
      </Typography>
    </CardContent>
  </Card>);

export default class FoodMenyAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {expanded: true};
  }

  render() {
    return (
      <Grid item xs={12}>
        <Accordion elevation={0} className="width-100"
          expanded={this.state.expanded}
          onChange={() => this.setState({expanded: !this.state.expanded})} >
          {!this.props.hideHeader &&
          <>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" color="primary">
                {this.props.header}
              </Typography>
            </AccordionSummary>
            <Divider variant="middle" />
          </>
          }
          <AccordionDetails>
            <FoodItems data={this.props.data} hidePrice={this.props.hidePrice}/>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }
}

FoodMenyAccordion.defaultProps = {
  hideHeader: false,
  hidePrice: false,
};

FoodMenyAccordion.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
  hidePrice: PropTypes.bool,
};
