import {
  SET_SCROLL,
} from '@Actions/scroll';

// IsScrolled is the state of the navbar.
const initialScrollState = {
  isScrolled: false,
  scrollHeight: 0,
};

// eslint-disable-next-line default-param-last
export default function scroll(state = {...initialScrollState}, action) {
  switch (action.type) {
    case SET_SCROLL:
      // eslint-disable-next-line no-case-declarations
      const {event} = action;
      return {
        ...state,
        isScrolled: event.scrolled,
        scrollHeight: event.height,
      };
    default:
      return state;
  }
}
