import api from 'App/api';

export const LOAD_CONTACTPERSONS_BEGIN = 'LOAD_CONTACTPERSONS_BEGIN';
export const LOAD_CONTACTPERSONS_SUCCESS = 'LOAD_CONTACTPERSONS_SUCCESS';
export const LOAD_CONTACTPERSONS_FAIL = 'LOAD_CONTACTPERSONS_FAIL';

export const loadContactPersonsBegin = () => ({
  type: LOAD_CONTACTPERSONS_BEGIN,
});

export const loadContactPersonsSuccess = (contactPersonsObj) => ({
  contactPersonsObj,
  type: LOAD_CONTACTPERSONS_SUCCESS,
});

export const loadContactPersonsFail = (error) => ({
  error,
  type: LOAD_CONTACTPERSONS_FAIL,
});

export const fetchContactPersons = () => (dispatch) => {
  dispatch(loadContactPersonsBegin());
  api.get('contact_persons').
      then((res) => res.data).
      then((json) => {
        const contactPersonsObj = {contactPersons: json};
        dispatch(loadContactPersonsSuccess(contactPersonsObj));
      }).
      catch((err) => {
        dispatch(loadContactPersonsFail(err));
      });
};
