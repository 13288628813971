import {
  LOAD_OPENHOURS_BEGIN,
  LOAD_OPENHOURS_FAIL,
  LOAD_OPENHOURS_SUCCESS,
} from '@Actions/openHours.js';

const initialOpenHoursStates = {
  openingHours: {
    error: false,
    errorMsg: '',
    fetched: false,
    fetching: false,
    hours: {},
  },
};

// eslint-disable-next-line
  export default function openHoursReducer(state = {...initialOpenHoursStates}, action) {
  switch (action.type) {
    case LOAD_OPENHOURS_BEGIN:
      return {
        ...state,
        openingHours: {
          ...state.openingHours,
          fetched: false,
          fetching: true,
        },
      };
    case LOAD_OPENHOURS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {openingHoursObj} = action;
      return {
        ...state,
        openingHours: {
          ...state.openingHours,
          error: false,
          fetched: true,
          fetching: false,
          hours: openingHoursObj.hours,
        },
      };
    case LOAD_OPENHOURS_FAIL:
      // eslint-disable-next-line no-case-declarations
      const {openingHoursError} = action;
      return {
        ...state,
        openingHours: {
          ...state.openingHours,
          error: true,
          errorMsg: openingHoursError,
          fetched: true,
          fetching: false,
        },
      };
    default:
      return state;
  }
}
