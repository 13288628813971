import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import _theme from '@Styling/_theme.module.scss';

// eslint-disable-next-line max-statements
const getSpacing = (props) => {
  let noSpacing = {};
  let extraWidth = 0;
  let extraHeight = 0;

  if (props.left) {
    noSpacing = {...noSpacing,
      marginLeft: `${-_theme.spacing * props.spacing}px`};
    extraWidth += props.spacing;
  }
  if (props.right) {
    noSpacing = {...noSpacing,
      marginRight: `${-_theme.spacing * props.spacing}px`};
    extraWidth += props.spacing;
  }
  if (props.top) {
    noSpacing = {...noSpacing,
      marginTop: `${-_theme.spacing * props.spacing}px`};
    extraHeight += props.spacing;
  }
  if (props.bottom) {
    noSpacing = {...noSpacing,
      marginBottom: `${-_theme.spacing * props.spacing}px`};
    extraHeight += props.spacing;
  }
  noSpacing = {...noSpacing,
    height: `calc(100% + ${_theme.spacing * extraHeight}px)`,
    width: `calc(100% + ${_theme.spacing * extraWidth}px)`};
  return noSpacing;
};

export default class NoSpacingGrid extends React.Component {
  render() {
    const {spacing, left, top, right, bottom, ...props} = this.props;
    const noSpacing = getSpacing(
        spacing,
        left,
        right,
        top,
        bottom,
    );

    return (
      <Grid
        style={noSpacing}
        {...props}
      >
        {this.props.children}
      </Grid>
    );
  }
}

NoSpacingGrid.defaultProps = {
  bottom: false,
  left: false,
  right: false,
  spacing: 0,
  top: false,
};

NoSpacingGrid.propTypes = {
  bottom: PropTypes.bool,
  children: PropTypes.node,
  left: PropTypes.bool,
  right: PropTypes.bool,
  spacing: PropTypes.number,
  top: PropTypes.bool,
};
