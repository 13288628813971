import React, {useEffect, useState} from 'react';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import {useAuth} from '@Hooks/AuthContext';

// eslint-disable-next-line max-lines-per-function
export const LoginAlert = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [message, setMessage] = useState('');
  const [prevOpen, setPrevOpen] = useState(false);

  const handleClose = (event) => {
    setPrevOpen(open);
    setOpen(false);
  };

  const {isAuthenticated, currentUser} = useAuth();

  const checkLogout = () => {
    if (prevOpen !== open && prevOpen) {
      setOpen(true);
      setUser(currentUser);
      setMessage('Hej då! Du är utloggad');
    }
  };

  const checkLogin = () => {
    if (!open && prevOpen && isAuthenticated) {
      setUser(currentUser);
      setOpen(true);
      setMessage('Välkommen tillbaka ');
    }
  };

  useEffect(() => {
    setOpen(false);
    checkLogout();
    checkLogin();
    setPrevOpen(isAuthenticated);
    return () => {
      setOpen(false);
      setPrevOpen(isAuthenticated);
    };
  }, [isAuthenticated, currentUser]);

  return (
    <React.Fragment>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        action={<React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>}
      >
        <Alert
          severity="success"
          variant="filled"
        >
          {message} {user}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};
