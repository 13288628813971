import React from 'react';
import {Redirect} from 'react-router-dom';

import {useAuth} from '@Hooks/AuthContext';

const Logout = function Logout(props) {
  const {isAuthenticated, logout} = useAuth();

  const preLoginRoute = JSON.parse(sessionStorage.getItem('preLoginRoute'));
  const redirectRoute = preLoginRoute ?
    preLoginRoute :
    '/';

  if (isAuthenticated) {
    logout();
  }

  return <Redirect to={redirectRoute} />;
};

export default Logout;
